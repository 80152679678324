<div id="outer" class="w-full overflow-clip">
    <div id="inner" class="flex h-full w-fit gap-[var(--padding)]">
        <!-- content is duplicted to fill the entire width -->
        <slot />
        <slot />
        <slot />
        <slot />
    </div>
</div>

<style lang="postcss">
    :root {
        /* Number of `<slot />`s */
        --repetitions: 4;
        --duration: 45s;

        /* Spacing between items */
        --padding: 24px;

        /* No fade on sides on mobile */
        --mask-width: 0;
        /* Fade on sides on desktop tailwind `sm` breakpoint */
        @media (min-width: 640px) {
            --mask-width: calc(var(--padding) * 2);
        }
    }

    #outer {
        /* transition to transparent (left and right) */
        mask: linear-gradient(
            90deg,
            transparent,
            white var(--mask-width),
            white calc(100% - var(--mask-width)),
            transparent
        );
    }

    #inner {
        /* animate scroll */
        animation: scroll var(--duration) forwards linear infinite;

        /* pause animation when hovering */
        /* &:hover {
            animation-play-state: paused;
        } */
    }

    @keyframes scroll {
        to {
            /* move over by one repetition, then reset animation */
            transform: translate(
                calc(calc(-100% / var(--repetitions)) - calc(var(--padding) / var(--repetitions)))
            );
        }
    }
</style>
