<script lang="ts">
    import { goto } from "$app/navigation"
    import GroupProfileHorizontal from "$components/group/GroupProfileHorizontal.svelte"
    import Footer from "$components/layout/Footer.svelte"
    import NavBar from "$components/layout/NavBar.svelte"
    import Page from "$components/layout/Page.svelte"
    import BlogPreview from "$components/marketing/BlogPreview.svelte"
    import FeaturePreview from "$components/marketing/FeaturePreview.svelte"
    import ScrollerHorizontal from "$components/marketing/ScrollerHorizontal.svelte"
    import Button from "$components/tui/Button.svelte"
    import ButtonChevron from "$components/tui/ButtonChevron.svelte"
    import blogs from "$docs/blogs"
    import features from "$docs/features"
    import api from "$lib/api"
    import { auth } from "$lib/model/Auth"
    import { isMobileNativeApp } from "$lib/settings/layout"
    import { chevron_right, play_fill } from "@timephy/tui-icons-svelte"
    import posthog from "posthog-js"
    import { Icon } from "svelte-awesome"
    import lang from "$lang"

    const { signedIn$, accountData$ } = auth

    /* ========================================================================================== */

    async function clickCreateGroup() {
        posthog.capture("create group")

        let result = await api.group.create()
        if (result.ok) {
            let groupId = result.value
            await goto(`/group/${groupId}?join`)
        } else {
            console.error(result.error)
            alert("Sorry, failed to create group. Please try again.")
        }
    }

    async function clickJoinMyGroup(group_id: string) {
        posthog.capture("join my group")

        goto(`/group/${group_id}?join`)
    }

    $: myGroups = $signedIn$ ? api.group.list_my() : Promise.resolve(null)

    /* ========================================================================================== */

    const featuredBlogIds: (keyof typeof blogs)[] = [
        "release-1-2",
        "how-to-watch-youtube-together",
        "how-to-listen-to-music-together",
        "alternative-to-watch2gether",
    ]
</script>

<Page>
    <NavBar slot="nav" />

    <div class="h-12 md:h-24 lg:h-36" />

    <div id="title-and-my-groups" class="flex w-full flex-col items-center gap-16 p-6">
        <div class="flex w-full flex-col items-center gap-8">
            <!-- !! Title Text -->
            <div class="flex w-full flex-col items-center gap-6">
                <h1
                    class="whitespace-pre-line text-center text-3xl font-semibold leading-tight sm:text-[2.5rem] sm:leading-tight"
                >
                    <!-- {#if $accountData$ === null} -->
                    {$lang.home.Title()}
                    <!-- {:else}
                        {$lang.home.Welcome_back()}
                        <br />
                        {$accountData$.name}
                    {/if} -->
                </h1>
                <h2
                    class="whitespace-pre-line text-center text-base leading-normal text-step-600 sm:text-lg sm:leading-normal"
                >
                    {$lang.home.Slogan()}
                </h2>
            </div>

            <!-- !! Watch Showcase -->
            {#if !$signedIn$}
                <Button
                    class="rounded-full"
                    color="gray"
                    on:click={() =>
                        window.open("https://www.youtube.com/watch?v=4F13mdc0u_k", "_blank")}
                    icon={play_fill}
                >
                    {$lang.home.Watch_Showcase()}
                </Button>
            {/if}

            <!-- !! Create Group -->
            <div class="flex w-full flex-col items-center gap-6">
                <h3 class="flex w-full flex-col items-center">
                    <Button
                        color="green"
                        class="h-[54px] w-full rounded-2xl border-2 text-lg sm:max-w-[22rem]"
                        on:click={() => clickCreateGroup()}
                    >
                        <p class="[text-shadow:_0_0_8px_rgb(0_0_0_/_50%)]">
                            {$lang.home.Create_Your_Group()}
                        </p>
                    </Button>
                </h3>

                <!-- !! Footer -->
                {#if !$signedIn$}
                    <p class="text-center text-xs text-step-500">
                        {$lang.home.Create_Your_Group_Confidence()}
                    </p>
                {/if}
            </div>
        </div>

        <!-- !! Quick Guide -->
        {#if !$signedIn$}
            <div
                id="quick-guide"
                class="flex items-center justify-center gap-[14px] text-xs text-step-500 sm:text-sm"
            >
                <div class="flex flex-col items-center sm:flex-row sm:gap-[8px]">
                    <p class="text-center">
                        <span>1.&nbsp;</span>

                        <span class="font-medium text-step-600">
                            {$lang.home.quick_guide.Step_1()}
                        </span>
                    </p>
                </div>

                <Icon class="h-3 w-3" data={chevron_right} />

                <div class="flex flex-col items-center sm:flex-row sm:gap-[8px]">
                    <p class="text-center">
                        <span>2.&nbsp;</span>

                        <span class="font-medium text-step-600">
                            {$lang.home.quick_guide.Step_2()}
                        </span>
                    </p>
                </div>

                <Icon class="h-3 w-3" data={chevron_right} />

                <div class="flex flex-col items-center sm:flex-row sm:gap-[8px]">
                    <p class="text-center">
                        <span>3.&nbsp;</span>

                        <span class="font-medium text-step-600">
                            {$lang.home.quick_guide.Step_3()}
                        </span>
                    </p>
                </div>
            </div>
        {/if}

        <!-- !! My Groups -->
        {#await myGroups then groups}
            {#if groups?.ok && groups.value.length}
                <div class="settings card sm:max-w-sm">
                    <div>
                        <h3 class="!text-xl !font-semibold">{$lang.home.Your_Groups()}</h3>
                        <p>{$lang.home.Your_Groups_DESC()}</p>
                    </div>
                    <div>
                        {#each groups.value as myGroup}
                            <ButtonChevron
                                class="overflow-hidden pl-0"
                                on:click={() => clickJoinMyGroup(myGroup.group.id)}
                            >
                                <GroupProfileHorizontal
                                    profile={myGroup.group}
                                    detail="{myGroup.member_count} {$lang.lower_case.Members()} · {myGroup.online_count} {$lang.lower_case.Online()}"
                                />
                            </ButtonChevron>
                        {/each}
                    </div>
                </div>
            {/if}
        {/await}
    </div>

    <div class="h-[4.5rem]"></div>

    <!-- !! Features -->
    <div id="features" class="flex w-full flex-col items-center gap-8">
        <!-- ! Features -->
        <!-- extend ScrollerHorizontal past safearea left and right (for mobile landscape) -->
        <div class="w-full">
            <div
                class="ml-[calc(-1*env(safe-area-inset-left))] mr-[calc(-1*env(safe-area-inset-right))]"
            >
                <ScrollerHorizontal>
                    {#each Object.entries(features) as [featureId, feature]}
                        <FeaturePreview {featureId} {feature} class="w-72 sm:w-80" />
                    {/each}
                </ScrollerHorizontal>
            </div>
        </div>
        <a
            class="flex items-center gap-[6px] text-blue-000 hover:text-blue-100 active:text-blue-200"
            href="/feature"
        >
            <p class="line-clamp-1 text-sm">{$lang.All()} Features</p>
            <Icon data={chevron_right} class="h-4 w-4 shrink-0" />
        </a>
    </div>

    <div class="h-[3rem]" />

    <!-- !! Blog -->
    <div id="blog" class="flex w-full flex-col items-center gap-8 p-6">
        <div class="flex w-full max-w-[26rem] flex-col gap-6 sm:max-w-[43rem]">
            {#each featuredBlogIds as featuredBlogId}
                <BlogPreview
                    blogId={featuredBlogId}
                    blog={blogs[featuredBlogId]}
                    class="sm:h-[19rem]"
                />
            {/each}
        </div>
        <a
            class="flex items-center gap-[6px] text-blue-000 hover:text-blue-100 active:text-blue-200"
            href="/blog"
        >
            <!-- TODO: Remove this and move All Blogs section to NavBar? -->
            <p class="line-clamp-1 text-sm">{$lang.All()} Blogs</p>
            <Icon data={chevron_right} class="h-4 w-4 shrink-0" />
        </a>
    </div>

    <div slot="footer">
        {#if !isMobileNativeApp}
            <Footer />
        {/if}
    </div>
</Page>
